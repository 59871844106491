<template>
  <text-rich v-model="content" @change="change"></text-rich>
</template>
<script>

  import TextRich from '../../../components/TextRich/index'

  export default {
    data () {
      return {
        content: ''
      }
    },
    methods: {
      change () {
        this.$nextTick(() => {
          console.log(this.content)
        })
      }
    },
    components: { TextRich }
  }
</script>
